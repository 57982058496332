<template>
  <CCard>
    <CCardHeader>
      <slot name="header"> <CIcon name="cil-grid" /> {{ caption }} </slot>
    </CCardHeader>
    <CCardBody>
      <CAlert show color="info"
        >To update these information, please contact the OneCloud team by email
        (aws-ccoe@equans.com) or RISE ticket</CAlert
      >
      <div v-if="!loading">
        <CDataTable
          :hover="hover"
          :striped="striped"
          :border="border"
          :small="small"
          :fixed="fixed"
          :items="items"
          :fields="fields"
          :items-per-page="small ? 20 : 10"
          :dark="dark"
          clickableRows
          :sorter="true"
        >
        </CDataTable>
      </div>
      <div v-else align="center">
        <img src="/img/tenor.gif" width="50" />
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: "TableAccountInfo",
  props: {
    items: Array,
    fields: {
      type: Array,
      default() {
        return ["Attribute", "Value"];
      }
    },
    caption: {
      type: String,
      default: "Table"
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  watch: {
    items: function(newItem) {
      if (newItem != undefined) this.loading = false;
    }
  },
  data() {
    return {
      loading: this.items === undefined
    };
  }
};
</script>
